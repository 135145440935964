<template>
  <div>
    <div class="h head-container">
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="交货日期起" end-placeholder="交货日期止" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" style="width: 260px" @change="toQuery" />
      <el-input v-model="query.keywords" :maxlength="20" placeholder="输入商品名称/编码等搜索" @keypress.enter.native="toQuery" @clear="toQuery" class="filter-item" style="width: 200px;" />
      <el-checkbox v-model="onlyNeedPurchase" class="filter-item" style="padding: 5px 10px 0 10px;" @change="page = 1">仅显示需采购零售</el-checkbox>
      <el-button class="filter-item" type="success" icon="el-icon-search" @click.stop="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table ref="list" :data="pagedData" border empty-text="没有相关商品" max-height="480" @selection-change="handleSelectionChange" v-loading="loading">
      <el-table-column type="selection" width="35" />
      <el-table-column prop="erpCode" label="ERP编码" width="130" />
      <el-table-column prop="goodsName" label="商品" min-width="180" show-overflow-tooltip />
      <el-table-column prop="specs" label="规格" min-width="120" show-overflow-tooltip :formatter="$goodsSpecConvert" />
      <el-table-column prop="quantity" label="销售数量" align="center" width="90" />
      <el-table-column prop="sendCount" label="已发货数量" align="center" width="90" />
      <el-table-column prop="onWay" label="在途数量" align="center" width="90" />
      <el-table-column prop="stock" label="当前库存" align="center" width="90" />
      <el-table-column prop="_needPurchaseNum" label="应采购数量" align="center" width="90" />
    </el-table>
    <el-pagination :total="filteredData.length" :current-page.sync="page" :page-size.sync="size" style="margin-top: 8px" layout="total, prev, pager, next, sizes" @size-change="sizeChange" />

    <div style="margin-top: 12px">
      <el-button type="primary" :disabled="loading || !selection || !selection.length" @click="handleDetail">查看零售明细</el-button>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import initData from "@/mixins/initData";

export default {
  mixins: [initData],
  data() {
    return {
      page: 1,
      size: 20,
      store: [],
      selection: [],
      onlyNeedPurchase: true,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        dateRange: null,
        keywords: null,
      },
    };
  },
  computed: {
    filteredData() {
      if (this.onlyNeedPurchase) {
        return this.store.filter(g => g._needPurchaseNum > 0);
      }
      return this.store;
    },
    pagedData() {
      let start = (this.page - 1) * this.size,
        len = this.size;
      if (start + len > this.filteredData.length) {
        len = this.filteredData.length - start;
      }
      return this.filteredData.slice(start, start + len);
    },
  },
  methods: {
    toQuery() {
      let params = JSON.parse(JSON.stringify(this.query));
      if (params.dateRange && params.dateRange.length === 2) {
        params.deliveryDateMin = params.dateRange[0];
        params.deliveryDateMax = params.dateRange[1];
      }
      delete params.dateRange;
      request({
        url: "api/shop/orderFormFull/purchase/groupByGoods",
        method: "get",
        params,
      }).then((res) => {
        (res || []).forEach((g) => {
          let c = g.quantity - g.sendCount - g.onWay - g.stock;
          if (c < 0) c = 0;
          g._needPurchaseNum = c;
        });
        this.store = res;
      });
    },
    sizeChange() {
      this.page = 1;
    },
    handleDetail() {
      if (this.selection && this.selection.length) {
        this.$emit(
          "drill",
          this.selection,
          this.params.deliveryDateMin,
          this.params.deliveryDateMax
        );
        this.selection = [];
        this.$refs.list && this.$refs.list.clearSelection();
      }
    },
    handleSelectionChange(selection) {
      this.selection = selection;
    },
  },
  mounted() {
    this.toQuery();
  },
};
</script>